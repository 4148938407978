import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'
import { Link } from 'gatsby'

export const Element = styled((props) => <Link {...props} />)`
  color: var(--black);
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.2em;
  text-align: left;
  border: none;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;

  &:visited {
    color: var(--black);
  }

  &:hover {
  }
  &.gray {
    color: #00000080;
  }
  &.white {
    color: var(--beige);
  }
  &.withHover:hover {
    color: var(--beige);
  }
  ${mediaMax('mobile')} {
    font-size: 10px;
    line-height: 12px;
  }
`
