import React from 'react'
import { LegalLink, Links, LinkItem } from './index.styled'
import fhnFile from 'static/fairhousingnotice.pdf'
import sopFile from 'static/NY-StandardOperatingProcedures.pdf'

const FooterSecondary = ({ onClose }) => {
  return (
    <Links>
      <LegalLink to="/legal" onClick={onClose}>
        Legal
      </LegalLink>
      <LinkItem href={fhnFile} target="_blank" rel="noreferrer">
        FAIR HOUSING NOTICE
      </LinkItem>
      <LinkItem href={sopFile} target="_blank" rel="noreferrer">
        STANDARD OPERATING PROCEDURES
      </LinkItem>
    </Links>
  )
}

export default FooterSecondary
