import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
} from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(62)};
  height: ${calculateResponsiveSize(67)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--black);
  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(47)};
    height: ${calculateMobileResponsiveSize(51)};
  }
`
export const Wrap = styled.div`
  position: fixed;
  left: ${calculateResponsiveSize(32)};
  top: 0;
  display: flex;
  z-index: 102;
  justify-content: space-between;
  align-items: center;
  padding-top: ${calculateResponsiveSize(43)};
  transition: 0.5s, transform 1s 0.5s;

  &.headerRight {
    right: ${calculateResponsiveSize(30)};
    left: auto;
    gap: ${calculateResponsiveSize(24)};
    padding-top: ${calculateResponsiveSize(67)};
    align-items: flex-start;
  }
  .open &.headerRight a {
    opacity: 0;
  }
  &.white {
    ${Logo} {
      background: var(--beige);
    }
  }
  transform: translateY(0);
  &.hide {
    transform: translateY(-101%);
  }

  ${mediaMax('mobile')} {
    padding-top: 0;

    left: ${calculateMobileResponsiveSize(25)};
    height: ${calculateMobileResponsiveSize(120)};
    & .contact-btn {
      display: none;
    }
    &.headerRight {
      padding-top: 0;
      align-items: center;
      right: ${calculateMobileResponsiveSize(32)};
      height: ${calculateMobileResponsiveSize(100)};
    }
  }
`
