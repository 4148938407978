import React from 'react'
import { ScrollSmoother } from 'gsap-wrapper'
import { MenuButtonIcon } from './index.styled'

const MenuButton = ({ className = '' }) => {
  const onClick = () => {
    const scrollerSmoother = ScrollSmoother.create({
      content: '.home-content',
      wrapper: '.home-wrapper',
      smooth: 2,
      effects: true,
    })

    const menu = document.querySelector('#menu')
    menu.classList.toggle('open')

    document.body.classList.toggle('open')

    /*  if (menu.classList.contains('open')) {
      scrollerSmoother.paused(true)
    } else {
      scrollerSmoother.paused(false)
    } */
  }

  return (
    <MenuButtonIcon onClick={onClick} className={className} id="menu-button">
      <span></span>
      <span></span>
      <span></span>
    </MenuButtonIcon>
  )
}

export default MenuButton
