import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
} from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'

import MenuIcon from 'icons/menu.svg'
import Menu1Icon from 'icons/menu1.svg'
import Menu2Icon from 'icons/menu2.svg'

export const MenuButtonIcon = styled.div`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -${calculateResponsiveSize(2)};

  span:first-child {
    display: flex;
    width: ${calculateResponsiveSize(20)};
    height: ${calculateResponsiveSize(4)};
    -webkit-mask: url(${MenuIcon}) no-repeat;
    -webkit-mask-size: contain;
    background: var(--black);
    transition: 0.5s;
  }
  span:nth-child(2) {
    display: flex;
    transition: 0.5s;
    width: ${calculateResponsiveSize(20)};
    height: ${calculateResponsiveSize(8)};
    overflow: hidden;
    &:after {
      content: '';
      display: flex;
      width: ${calculateResponsiveSize(20)};
      height: ${calculateResponsiveSize(8)};
      -webkit-mask: url(${Menu1Icon}) no-repeat;
      -webkit-mask-size: contain;
      background: var(--black);
      transition: 0.5s;
      flex: 0 0 auto;
    }

    position: absolute;
  }

  span:nth-child(3) {
    display: flex;
    transition: 0.5s;
    width: ${calculateResponsiveSize(20)};
    height: ${calculateResponsiveSize(13)};
    overflow: hidden;
    &:after {
      content: '';
      display: flex;
      width: ${calculateResponsiveSize(20)};
      height: ${calculateResponsiveSize(13)};
      -webkit-mask: url(${Menu2Icon}) no-repeat;
      -webkit-mask-size: contain;
      background: var(--black);
      transition: 0.5s;
      flex: 0 0 auto;
    }

    position: absolute;
  }

  &.white {
    span:first-child {
      background: var(--beige);
    }
    span:nth-child(2),
    span:nth-child(3) {
      &:after {
        background: var(--beige);
      }
    }
  }

  body:not(.open) &:hover {
    span:nth-child(2) {
      width: ${calculateResponsiveSize(15)};
    }
    span:nth-child(3) {
      width: ${calculateResponsiveSize(10)};
    }
  }

  body.open & {
    span:first-child {
      transform: rotate(45deg)
        translate(${calculateResponsiveSize(6)}, ${calculateResponsiveSize(2)});
      width: ${calculateResponsiveSize(25)};
      height: ${calculateResponsiveSize(5)};
      background: var(--black);
    }
    span:nth-child(3) {
      width: ${calculateResponsiveSize(25)};
      height: ${calculateResponsiveSize(15.7)};
      &:after {
        width: ${calculateResponsiveSize(25)};
        height: ${calculateResponsiveSize(15.7)};
        background: var(--black);
      }
      transform: rotate(-45deg)
        translate(${calculateResponsiveSize(2)}, -${calculateResponsiveSize(4)});
    }
    span:nth-child(2) {
      opacity: 0;
    }
  }

  ${mediaMax('mobile')} {
    margin-top: -${calculateMobileResponsiveSize(2)};
    span:first-child {
      width: ${calculateMobileResponsiveSize(20)};
      height: ${calculateMobileResponsiveSize(4)};
    }
    span:nth-child(2) {
      width: ${calculateMobileResponsiveSize(20)};
      height: ${calculateMobileResponsiveSize(8)};
      &:after {
        width: ${calculateMobileResponsiveSize(20)};
        height: ${calculateMobileResponsiveSize(8)};
      }
    }
    span:nth-child(3) {
      width: ${calculateMobileResponsiveSize(20)};
      height: ${calculateMobileResponsiveSize(13)};
      &:after {
        width: ${calculateMobileResponsiveSize(20)};
        height: ${calculateMobileResponsiveSize(13)};
      }
    }

    body.open & {
      span:first-child {
        transform: rotate(45deg)
          translate(
            ${calculateMobileResponsiveSize(6)},
            ${calculateMobileResponsiveSize(2)}
          );
        width: ${calculateMobileResponsiveSize(25)};
        height: ${calculateMobileResponsiveSize(5)};
      }
      span:nth-child(3) {
        width: ${calculateMobileResponsiveSize(25)};
        height: ${calculateMobileResponsiveSize(15.7)};
        &:after {
          width: ${calculateMobileResponsiveSize(25)};
          height: ${calculateMobileResponsiveSize(15.7)};
        }
        transform: rotate(-45deg)
          translate(
            ${calculateMobileResponsiveSize(2)},
            -${calculateMobileResponsiveSize(4)}
          );
      }
      span:nth-child(2) {
        opacity: 0;
      }
    }
    body:not(.open) &:hover {
      span:nth-child(2) {
        width: ${calculateMobileResponsiveSize(25)};
      }
      span:nth-child(3) {
        width: ${calculateMobileResponsiveSize(25)};
      }
    }
  }
`
