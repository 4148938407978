import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'gsap-wrapper'
import Header from 'components/header'
import Footer from 'components/footer'
import CookiesBanner from '../ui/cookies-banner'
import NavigationNext from '../ui/navigation-next'
import Menu from '../header/menu'
import HeaderTransition from '../ui/header-transition'

import 'styles/fonts.css'
import GlobalStyle from 'styles/global'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import useWindowSize from '../../styles/window-size'
import ThemeProvider from '../../styles/theme-provider'

import ProtectSection from 'components/protect-section'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 2000, exit: 2000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <HeaderTransition status={status}>
              <LayoutWrapper>{children}</LayoutWrapper>
            </HeaderTransition>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props
  const refWrapper = React.useRef(null)
  const scrollSmoother = React.useRef(null)

  const isHomePage = location.pathname === '/'
  // const [isFirstLoad, setIsFirstLoad] = useState(true)
  // console.dir(layout)
  const [isProtect, setIsProtect] = React.useState(true)

  const { isMobile } = useWindowSize()

  React.useLayoutEffect(() => {
    const gsapContext = gsap.context(() => {
      scrollSmoother.current = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 0.1,
        effects: false,
      })
      // pin
      gsap.utils.toArray('.pin').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          pinSpacing: false,
          pin: true,
          start: 'bottom bottom',
          anticipatePin: 1,
        })
      })
      // fadeIn
      gsap.utils.toArray('.fadeIn').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          start: 'top 90%',
          end: '+=1000%',
          toggleClass: {
            targets: container,
            className: 'visible',
            toggle: 'add',
          },
        })
      })
      gsap.utils.toArray('.parallax').forEach(function (container) {
        scrollSmoother.current.effects(container.querySelectorAll('.content'), {
          speed: '0.6s',
        })
      })

      /* setTimeout(
        () =>
          !(location.pathname === '/' && location.hash === '') &&
          document
            .querySelector('.userway_buttons_wrapper')
            ?.classList.add('show'),
        2000,
      ) */
    }, refWrapper)

    return () => gsapContext.revert()
  }, [children, isMobile])

  return (
    <ThemeProvider
      location={location}
      scrollSmoother={scrollSmoother}
      theme={layout}
    >
      <div className="home-wrapper" ref={refWrapper}>
        {/*  <ProtectSection /> */}

        <CookiesBanner />
        <Header />
        <Menu />

        <div
          className={[
            'home-content',
            location.pathname === '/'
              ? 'home-page'
              : `${location.pathname.replaceAll('/', '')}-page`,
          ].join(' ')}
        >
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          <NavigationNext />
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
