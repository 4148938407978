import React from 'react'
import { Link } from 'gatsby-link'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'

import ArrowIcon from 'icons/arrow.svg'

export const Next = styled.div`
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(15)};
  font-weight: 700;
  line-height: ${calculateResponsiveSize(25)};
  letter-spacing: 0.32em;
  text-align: center;
  text-transform: uppercase;
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(12)};
    line-height: ${calculateMobileResponsiveSize(16)};
  }
`

export const Arrow = styled.div`
  background: var(--black);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-image: url(${ArrowIcon});

  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${ArrowIcon});

  width: ${calculateResponsiveSize(35)};
  height: ${calculateResponsiveSize(9)};
  display: flex;
  margin: 0 auto;
  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(35)};
    height: ${calculateMobileResponsiveSize(9)};
  }
`
export const ArrowWrap = styled.div`
  width: ${calculateResponsiveSize(0)};
  height: ${calculateResponsiveSize(9)};
  transition: 0.5s;
  overflow: hidden;
  left: calc(50% - ${calculateResponsiveSize(35 / 2)});
  position: relative;
  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(35)};
    height: ${calculateMobileResponsiveSize(9)};
    left: calc(50% - ${calculateMobileResponsiveSize(35 / 2)});
    margin-top: ${calculateMobileResponsiveSize(15)};
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${calculateResponsiveSize(125)} ${calculateResponsiveSize(104)}
    ${calculateResponsiveSize(0)};
  background: transparent;

  ${mediaMax('mobile')} {
    padding: ${calculateMobileResponsiveSize(60)}
      ${calculateMobileResponsiveSize(25)} ${calculateMobileResponsiveSize(60)};
  }
`
export const Right = styled((props) => <Link {...props} />)`
  color: var(--black);
  flex-direction: column;
  display: flex;
  text-decoration: none;
  gap: ${calculateResponsiveSize(8)};
  &:hover {
    ${ArrowWrap} {
      width: ${calculateResponsiveSize(35)};
    }
  }

  ${mediaMax('mobile')} {
    gap: ${calculateMobileResponsiveSize(10)};

    &:hover {
      ${ArrowWrap} {
        width: ${calculateMobileResponsiveSize(35)};
      }
    }
  }
`
