import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'

import HeaderLogo from 'icons/logo.svg'
import Leaves from 'static/pr-leaves.png'

export const Section = styled.div`
  background: var(--beige) /* url(${Leaves}) no-repeat top right */;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  height: 100dvh;
  z-index: 205;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`

export const Logo = styled.div`
  width: ${calculateResponsiveSize(62)};
  height: ${calculateResponsiveSize(67)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: #000;
  margin-top: 30vh;
  ${mediaMax('mobile')} {
    width: 62px;
    height: 67px;
    margin-top: 25vh;
  }
`
export const Desc = styled.div`
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(12)};
  font-weight: 700;
  line-height: ${calculateResponsiveSize(18)};
  letter-spacing: 0.32em;
  text-align: center;
  margin-top: ${calculateResponsiveSize(60)};
  margin-bottom: ${calculateResponsiveSize(20)};

  text-transform: uppercase;
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 60px;
    margin-bottom: 20px;
  }
`

export const Button = styled.button`
  width: ${calculateResponsiveSize(160)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(12)};
  font-weight: 700;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.32em;
  text-align: center;
  border: 1px solid #000;
  padding: ${calculateResponsiveSize(8.5)} 0;
  background: transparent;
  cursor: pointer;
  transition: 0.5s;
  color: var(--black);

  &:hover {
    background: #0000001f;
  }
  ${mediaMax('mobile')} {
    width: 160px;
    font-size: 12px;
    line-height: 12px;
    padding: 8.5px 0;
  }
`
export const TexteFieldWrap = styled.div`
  margin-top: ${calculateResponsiveSize(30)};
  margin-bottom: ${calculateResponsiveSize(30)};
  width: ${calculateResponsiveSize(560)};

  ${mediaMax('mobile')} {
    margin-top: 30px;
    margin-bottom: 54px;
    width: 260px;
  }
`
