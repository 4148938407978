import styled from 'styled-components'
import { calculateResponsiveSize } from 'styles/calculate-responsive-size'
import { mediaMax, mediaMin } from 'styles/media-queries'

export const Wrap = styled.div`
  width: 100%;
`

export const Title = styled.div`
  color: var(--black);

  margin-bottom: ${calculateResponsiveSize(3)};

  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(12)};
  font-weight: 700;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  &.error {
    color: #ff0c00;
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 3px;
  }
`
export const InputField = styled.input`
  -webkit-appearance: none;
  border-radius: 0;
  padding: ${calculateResponsiveSize(6)} 0;
  border: none;
  border-bottom: 1px solid var(--black);
  background: none;
  width: 100%;
  outline: none;

  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(12)};
  font-style: italic;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(14)};
  letter-spacing: 0px;
  text-align: left;
  color: #00000080;

  &.error {
    border-bottom: 1px solid #ff0c00;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--beige) inset !important;
    -webkit-text-fill-color: #001e6080;
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 14px;
    padding: 6px 0;
  }
`
