import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'
import { Link } from 'gatsby-link'
import CloseIcon from 'icons/close.svg'

export const Wrap = styled.div`
  .cookies-banner {
    position: fixed;
    background: #d6cec7;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    color: #000000;

    height: ${calculateResponsiveSize(50)};
    padding: ${calculateResponsiveSize(14)} ${calculateResponsiveSize(11)}
      ${calculateResponsiveSize(11)} ${calculateResponsiveSize(11)};

    font-family: 'Davis Sans';
    font-size: ${calculateResponsiveSize(16)};
    line-height: ${calculateResponsiveSize(22)};
    letter-spacing: 0em;
    width: 100%;
    bottom: ${calculateResponsiveSize(0)};
    transition: 1.5s;
    transform: translateY(100%);
  }
  &.show {
    .cookies-banner {
      transform: translateY(0%);
    }
  }
  .cookies-banner-btns {
    position: absolute;
    right: ${calculateResponsiveSize(100)};
  }
  .cookies-banner-accept-btn {
    display: none;
  }
  .cookies-banner-decline-btn {
    -webkit-mask-image: url(${CloseIcon});
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;

    mask-image: url(${CloseIcon});
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background: #000000;
    border: none;

    height: ${calculateResponsiveSize(15.4)};
    width: ${calculateResponsiveSize(15.4)};
    cursor: pointer;
    text-indent: -1000px;
    transition: 1s;
    line-height: 0;
  }

  ${mediaMax('mobile')} {
    .cookies-banner-btns {
      position: static;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .cookies-banner-accept-btn {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: center;
      padding: 6px 10px;
      text-transform: uppercase;
      border-radius: 36px;
      border: none;
      margin-left: 10px;
      color: #d6cec7;
      background: #000;
    }
    .cookies-banner-decline-btn {
      display: none;
    }
    .cookies-banner {
      height: 50px;
      padding: 0px 20px;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      left: 0;
      bottom: auto;
      top: 0;
      transition: 0.5s;
      transform: translateY(-100%);
    }
  }
`
export const Content = styled.div``
export const Button = styled.div`
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(12)};
  font-weight: 700;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.32em;
  text-align: center;
  background: none;
  width: ${calculateResponsiveSize(146)};
  border: 1px solid #000;
  text-transform: uppercase;
  transition: 1s;
  cursor: pointer;
  margin-left: ${calculateResponsiveSize(46)};
  height: ${calculateResponsiveSize(28)};
  &:hover {
    background: #ffffff;
  }
`

export const LinkItem = styled((props) => <Link {...props} />)`
  color: #000000;
  font-weight: 400;
  font-family: 'Davis Sans';

  font-size: ${calculateResponsiveSize(16)};
  line-height: ${calculateResponsiveSize(22)};
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;

  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 16px;
  }
`
