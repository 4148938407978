import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Wrapper,
  Logo,
  LinkItem,
  Contact,
  Icon,
  Links,
  LegalLink,
  Icons,
  Copyright,
} from './index.styled'
import fhnFile from 'static/fairhousingnotice.pdf'
import sopFile from 'static/NY-StandardOperatingProcedures.pdf'
import useWindowSize from '../../styles/window-size'
import NaftaliGroupLogo from 'icons/naftali-logo.svg'

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { phone, email, address, insta, sg },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          phone
          email
          address
          insta
          sg
        }
      }
    }
  `)
  const { isMobile } = useWindowSize()

  const onLeafClick = (e) => {
    /*  console.dir(e.target.classList)

    e.target.classList.add('click')

    setTimeout(() => {
      e.target.classList.remove('click')
    }, 500) */
  }

  return (
    <Wrapper>
      {!isMobile && (
        <a
          href="https://www.naftaligroup.com/"
          target="_blank"
          rel="noreferrer"
          className="nl"
        >
          <img
            src={NaftaliGroupLogo}
            alt="Naftali Group logo."
            className="naftaligroup"
          />
        </a>
      )}
      <Logo to="/" onClick={onLeafClick} />
      <Contact>
        <div>{address}</div>
        {isMobile && sg}
        <a href={`mailto:${email}`}>{email}</a>
        <a href={`tel:${phone}`} className="phone">
          {phone}
        </a>
      </Contact>
      {!isMobile && <Contact>{sg}</Contact>}
      {isMobile && (
        <Icons>
          <a
            href="https://www.naftaligroup.com/"
            target="_blank"
            rel="noreferrer"
            className="nl"
          >
            <img
              src={NaftaliGroupLogo}
              alt="Naftali Group logo."
              className="naftaligroup"
            />
          </a>
          <Icon href={insta} target="_blank" />
        </Icons>
      )}
      <Links>
        <LegalLink to="/legal">Legal</LegalLink>

        <LinkItem href={fhnFile} target="_blank" as="a" rel="noreferrer">
          FAIR HOUSING NOTICE
        </LinkItem>
        <LinkItem href={sopFile} target="_blank" as="a" rel="noreferrer">
          STANDARD OPERATING PROCEDURES
        </LinkItem>
        {!isMobile && <Icon href={insta} target="_blank" />}
      </Links>
      <Copyright>COPYRIGHT Ⓒ 2025 255 E 77. ALL RIGHTS RESERVED.</Copyright>
    </Wrapper>
  )
}

export default Footer
