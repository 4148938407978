import React from 'react'
import CookieConsent from 'react-cookie-consent'
import useWindowSize from '../../../styles/window-size'
import { LinkItem, Content, Wrap, Button } from './index.styled'

const CookiesBanner = () => {
  const ref = React.useRef()
  const refCookie = React.useRef()

  const { isMobile } = useWindowSize()

  React.useEffect(() => {
    setTimeout(() => {
      ref?.current?.classList?.add('show')
      // refCookie.current?.accept()
    }, 2500)
  }, [])

  return (
    <Wrap ref={ref}>
      <CookieConsent
        ref={refCookie}
        location="none"
        buttonText={isMobile ? 'Ok' : 'Accept'}
        disableStyles
        containerClasses="cookies-banner"
        buttonClasses="cookies-banner-accept-btn"
        disableButtonStyles
        enableDeclineButton
        buttonWrapperClasses="cookies-banner-btns"
        declineButtonClasses="cookies-banner-decline-btn"
        contentClasses="cookies-banner-content"
        expires={150}
        //debug={true}
      >
        <Content>
          {isMobile ? (
            <>
              This website uses <LinkItem to="/legal">cookies</LinkItem>.
            </>
          ) : (
            <>
              We use cookies to improve your experience. By using this website
              you agree to our <LinkItem to="/legal">Cookie Policy.</LinkItem>{' '}
              <Button
                white
                as="button"
                type="button"
                onClick={() => refCookie.current?.accept()}
                className="accept"
              >
                Accept all
              </Button>
            </>
          )}
        </Content>
      </CookieConsent>
    </Wrap>
  )
}
export default CookiesBanner
